import * as Types from "./Types";

const initialState = {
  isLoading: false,
  isCreate: false,
  brandList: [],
};
const BrandReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_BRAND:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.SUCCESS_CREATE:
      return {
        ...state,
        isCreate: action.payload,
      };
    case Types.BRAND_LIST:
      return {
        ...state,
        brandList: action.payload,
      };

    default:
      break;
  }
  return newState;
};
export default BrandReducer;
