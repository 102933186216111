export const CHANGE_INPUT_CAM = "CHANGE_INPUT_CAM";
export const IS_PAGE_LOAD = "IS_PAGE_LOAD";
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const AFTER_ASSIGN = "AFTER_ASSIGN";
export const IS_ASSIGN_DRIVER = "IS_ASSIGN_DRIVER";
export const IS_CAMPAIGN_LOAD = "IS_CAMPAIGN_LOAD";
export const IS_CAMPAIGN_ADDED = "IS_CAMPAIGN_ADDED";
export const CAMPAIGN_DETAILS = "CAMPAIGN_DETAILS";
export const CAMPAIGN_DETAILS_MAP = "CAMPAIGN_DETAILS_MAP";
export const DRIVER_DETAILS = "DRIVER_DETAILS";
