import { combineReducers } from "redux";
import AuthReducer from "src/modules/auth/_redux/AuthReducer";
import BrandReducer from "src/modules/brandAgencies/_redux/BrandReducer";
import CampaignReducer from "src/modules/campaign/_redux/CampaignReducer";
import DriverReducer from "src/modules/drivers/_redux/DriverReducer";
import Dashboard from "../../views/dashboard/redux/dashboardReducer";

// combine all of the reducers here
const rootReducer = combineReducers({
  authInfo: AuthReducer,
  brandInfo: BrandReducer,
  driverInfo: DriverReducer,
  campaignInfo: CampaignReducer,
  dashboard: Dashboard,
});

export default rootReducer;
