import * as Types from "./dashboardActionType";

const initialState = {
  dashboard: null,
  isLoading: false,
};

const DashboardReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_PAGE_LOAD:
      return {
        ...state,
        isPageLoad: action.payload,
      };
    case Types.GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    default:
      break;
  }
  return newState;
};
export default DashboardReducer;
