import * as Types from "./Types";

const initialState = {
  inputInfo: {
    campaignImage: "",
    campaignName: "",
    campaignStart: new Date(),
    campaignEnd: new Date(),
    brandID: "",
    wrapName: "",
    paymentType: "",
    amount: "",
    dailyTargetedDistance: "",
    totalTargetedDistance: "",
  },
  campaignList: [],
  isLoading: false,
  afterAssign: false,
  isAssign: false,
  isCampaignAdded: false,
  isCampaignLoad: false,
  campaignDetails: null,
  driverDetails: null,
  driverDetailsMap: null,
};
const CampaignReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_INPUT_CAM:
      const { name, value } = action.payload;
      const inputInfo = { ...state.inputInfo };
      inputInfo[name] = value;
      return {
        ...state,
        inputInfo: inputInfo,
      };
    case Types.CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload,
      };
    case Types.IS_PAGE_LOAD:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.AFTER_ASSIGN:
      return {
        ...state,
        afterAssign: action.payload,
      };
    case Types.IS_ASSIGN_DRIVER:
      return {
        ...state,
        isAssign: action.payload,
      };
    case Types.IS_CAMPAIGN_ADDED:
      const eInput = initialState.inputInfo;
      return {
        ...state,
        isCampaignAdded: action.payload,
        inputInfo: eInput,
      };
    case Types.IS_CAMPAIGN_LOAD:
      return {
        ...state,
        isCampaignLoad: action.payload,
      };
    case Types.CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.payload,
      };
    case Types.DRIVER_DETAILS:
      return {
        ...state,
        driverDetails: action.payload,
      };
    case Types.CAMPAIGN_DETAILS_MAP:
      return {
        ...state,
        driverDetailsMap: action.payload,
      };
    default:
      break;
  }
  return newState;
};
export default CampaignReducer;
