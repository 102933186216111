import * as Types from "./Types";

const initialState = {
  inputInfo: {
    lName: "",
    fName: "",
    email: "",
    phone: "",
    dob: "",
    address: "",
    nid: "",
    nidPic: "",
    licenceNo: "",
    licensePic: "",
    stickerType: "",
    photo: "",
    password: "",
    repeatPassword: "",
    carName: "",
    carModel: "",
    carMakingYear: "",
    carColor: "",
    carUseOfVehicle: "",
    vehiclePic: "",
    dailyDistanceCovered: "",
    registrationLastDate: "",
    taxTokenNo: "",
    taxTokenLastDate: "",
    taxTokenPic: "",
    carOwnerNid: "",
    carOwnerNidPic: "",
    insuranceLastDate: "",
    insurancePic: "",
  },
  isLoading: false,
  driverList: [],
  singleDriver: null,
};
const DriverReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.CHANGE_INPUT_VALUE:
      const { name, value } = action.payload;
      const inputInfo = { ...state.inputInfo };
      inputInfo[name] = value;
      return {
        ...state,
        inputInfo: inputInfo,
      };
    case Types.UPDATE_INPUT_VALUE:
      return {
        ...state,
        inputInfo: action.payload,
      };
    case Types.IS_DRIVER_ADDED:
      const inputEmpty = initialState.inputInfo;
      return {
        ...state,
        inputInfo: inputEmpty,
      };
    case Types.IS_DRIVER_LOAD:
      return {
        ...state,
        isLoading: action.payload,
      };
    case Types.DRIVER_LIST:
      return {
        ...state,
        driverList: action.payload,
      };
    case Types.SINGLE_DRIVER:
      return {
        ...state,
        singleDriver: action.payload,
      };
    default:
      break;
  }
  return newState;
};
export default DriverReducer;
